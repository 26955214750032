body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  width: 100%;
  height: 100%;
}

p,
button,
span,
input {
  font-size: 14px;
  font-family: "Roboto";
  outline: none;
}

p {
  margin: 0;
}

button {
  cursor: pointer;
}

input {
  display: block;
  width: 100%;
  border-radius: 5px;
  border: 1px solid black;
  padding: 17px 20px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

figure{
  margin: 0; 
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}